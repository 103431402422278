import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
import { ImagePicker } from "@awesome-cordova-plugins/image-picker/ngx";
import { NativeStorage } from "@awesome-cordova-plugins/native-storage/ngx";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { Stripe } from "@awesome-cordova-plugins/stripe/ngx";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { DocumentViewer } from "@awesome-cordova-plugins/document-viewer/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { FeaturesModule } from "./features/features.module";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
// import { AngularFirePerformanceModule } from "@angular/fire/compat/performance";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
import { GooglePlus } from "@awesome-cordova-plugins/google-plus/ngx";
import { Facebook } from "@awesome-cordova-plugins/facebook/ngx";
import { SignInWithApple } from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { NgStepperModule } from "angular-ng-stepper";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";

import { PDFGenerator } from "@awesome-cordova-plugins/pdf-generator/ngx";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { Printer } from "@awesome-cordova-plugins/printer/ngx";
import { EventStatusPipe } from "./pipes/event-status.pipe";
import { environment } from "../environments/environment";
import { NgxMapboxGLModule } from "ngx-mapbox-gl";

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";

import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { ServiceWorkerModule } from "@angular/service-worker";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    FeaturesModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapAccessToken, // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    // AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    HttpClientModule,
    GoogleMapsModule,
    CdkStepperModule,
    NgStepperModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    // provideAuth(() => getAuth()),
    // provideDatabase(() => getDatabase()),
    // // providePerformance(() => getPerformance()),
    // provideStorage(() => getStorage()),
    SocialLoginModule,
  ],
  exports: [NgxMapboxGLModule],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    Geolocation,
    InAppBrowser,
    BarcodeScanner,
    ImagePicker,
    Printer,
    Keyboard,
    PhotoViewer,
    GoogleMap,
    PDFGenerator,
    File,
    FileTransfer,
    DocumentViewer,
    GooglePlus,
    Facebook,
    SignInWithApple,
    NativeStorage,
    FileOpener,
    Diagnostic,
    Stripe,
    AndroidPermissions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        lang: "en",
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "269388067251-3eg03b24kctjjtqjtt5m0rp1ic4pq952.apps.googleusercontent.com"
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
